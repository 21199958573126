import axios from "axios";

const PRODUCTION = {
  PASSKEY: 'aaa29506-0faf-4c55-badb-30400d2aecbc',
  APIENDPOINT: 'https://backend.vsepr.pa',
  APIENDPOINT_FACERECOGNITION: 'https://llm.vsepr.pa:3000',
  APIENDPOINT_BRAINSTORM: 'https://llm.vsepr.pa:5000'
};

const STAGING = {
  PASSKEY: 'aaa29506-0faf-4c55-badb-30400d2aecbc',
  APIENDPOINT: 'https://spark-lab-dev.azurewebsites.net',
  // APIENDPOINT: 'http://localhost:4000',
  APIENDPOINT_FACERECOGNITION: 'https://llm.vsepr.pa:3000',
  // APIENDPOINT_FACERECOGNITION: 'https://face-recognition.thankfulhill-e523d0be.southeastasia.azurecontainerapps.io',
  // APIENDPOINT_BRAINSTORM: 'https://edie-lea-chatbot.wonderfulsand-0cd54424.southeastasia.azurecontainerapps.io'
  APIENDPOINT_BRAINSTORM: 'https://llm.vsepr.pa:5000'
};

export let PASSKEY;
if (window.environment === 'production') {
  PASSKEY = PRODUCTION.PASSKEY;
} else {
  PASSKEY = STAGING.PASSKEY;
}

export let APIENDPOINT;
if (window.environment === 'production') {
  APIENDPOINT = PRODUCTION.APIENDPOINT;
} else {
  APIENDPOINT = STAGING.APIENDPOINT;
}
export const axiosInstance = axios.create({
  withCredentials: true
});

export let APIENDPOINT_FACERECOGNITION;
if (window.environment === 'production') {
  APIENDPOINT_FACERECOGNITION = PRODUCTION.APIENDPOINT_FACERECOGNITION;
} else {
  APIENDPOINT_FACERECOGNITION = STAGING.APIENDPOINT_FACERECOGNITION;
}
export const axiosInstanceFaceRecognition = axios.create();

export let APIENDPOINT_BRAINSTORM;
if (window.environment === 'production') {
  APIENDPOINT_BRAINSTORM = PRODUCTION.APIENDPOINT_BRAINSTORM;
} else {
  APIENDPOINT_BRAINSTORM = STAGING.APIENDPOINT_BRAINSTORM;
}
export const axiosInstanceBrainstorm = axios.create();

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user'
};

console.log(`
  Running ${window.environment} environment:
  APIENDPOINT=${APIENDPOINT}
  APIENDPOINT_BRAINSTORM=${APIENDPOINT_BRAINSTORM}
  APIENDPOINT_FACERECOGNITION=${APIENDPOINT_FACERECOGNITION}
`);